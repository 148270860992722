const parseParams = (querystring) => {
    const params = new URLSearchParams(querystring);
    const obj = {};
    for (const key of params.keys()) {
        obj[key] = (params.getAll(key).length > 1) ? params.getAll(key) : params.get(key)
    }
    return obj;
};

const getQuery = function () {
    return parseParams(window.location.search);
}

export {getQuery}
